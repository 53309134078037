(function ($$ashChartOpts, $, undefined) {
  //ASHCHART OPTIONS
  var donut,
    chart,
    arc,
    tempOpts,
    s = {
      //default values
      fontFam: "'Inter', 'sans-serif'",
      fontColor: "#ffffff",
      fillColor: "#702F8A",
      secondaryFillColor: "#72cdc4", //in this case, self-measured steps
      bgColor: "#f1f1f1",
      capitalize: true,
    },
    opts = [
      ($$ashChartOpts.donut = $.extend({}, s)),
      ($$ashChartOpts.chart = $.extend({}, s)),
      ($$ashChartOpts.arc = $.extend({}, s)),
    ];

  //option to override default settings above
  donut = {
    gap: true,
    fontColor: s.fillColor,
    secondaryTextSize: 0.75,
  };
  chart = {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontColor: "#444",
  };
  arc = {
    bgColor: "#D6D6D6",
  };
  //end override options

  tempOpts = [donut, chart, arc];

  for (var i in opts) {
    if (opts.hasOwnProperty(i)) {
      $.extend(opts[i], tempOpts[i]);
    }
  }
})((window.$$ashChartOpts = window.$$ashChartOpts || {}), jQuery);

window.addEventListener("load", function () {
  $(".ashFilterTrigger").each(function () {
    var obj = $(this);

    if (!obj.hasClass("label_group")) {
      //fixing conflict with challenge ashFilterTrigger
      obj.ashFilter({
        event: "change",
        trigger: $(this),
        filterItem: $(".ashFilter"),
        addSelected: "selected",
        fadeInDur: 1,
        fadeOutDur: 0,
      });
    }
  });

  function selectPopulateDropdown(elemID, select2) {
    //populate a related dropdown
    var trigger = $(".ashFilterTrigger"),
      elemOpt = $(elemID + " option"),
      inquiryOpt = elemOpt.detach(), //remove and store all options in 2nd select
      elemEvent = "change",
      elemVal = "",
      newOpt;

    function buildOpts(obj, newOpt) {
      //Remove all 2nd selects options
      elemOpt.remove();

      //loop thru each option and append those whose filter = its tag
      obj.each(function () {
        var selObj = $(this);
        if (
          selObj.data("ash-filter") === undefined ||
          selObj.data("ash-filter").indexOf(newOpt) > -1
        ) {
          selObj.appendTo(elemID);
        }
      });
      $(elemID + " option:first-child").prop("selected", true);
    }

    if (trigger.length !== 0) {
      //disable the inquiry type dropdown
      $(elemID).prop("disabled", true);

      trigger.on(elemEvent, function () {
        elemVal = $(this).val();
        newOpt = $(
          'select.ashFilterTrigger option[value="' + elemVal + '"]'
        ).data("ash-filter-trigger"); //get filter tag

        //enable the inquiry type dropdown and setup validation
        $(elemID).prop("disabled", false);

        //run build options function
        buildOpts(inquiryOpt, newOpt);
      });
    } else {
      newOpt = $(elemID).data("ash-filter-default");
      //run build options function
      buildOpts(inquiryOpt, newOpt);
    }
  }
  if (!Modernizr.touchevents) {
    selectPopulateDropdown("#inquiryType", true); //select2 is supported
  } else {
    selectPopulateDropdown("#inquiryType", false); //select2 is NOT supported
    $$ash.removeSelectEmptyOption();
  }

  navigation();
  $$ash.metaNavInit(
    ".secondaryInfo .user-info a.dropdown-menu-trigger",
    ".secondaryInfo .user-info .dropdown-menu-cont"
  );

  setupAshModal($(".modalOpen"));
});
