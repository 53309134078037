//loop through all links on page; if an external link, send user to redirect page
function linkRedirect(linkstoRedir) {
  var delaySeconds = 30; //amount of seconds to keep user on redirect page
  var delay = delaySeconds * 1000;

  if (typeof $$ashRedirect !== 'undefined') {
    linkstoRedir.forEach(function(link, index) {
      var redirect = $$ashRedirect;
      //if the redirect has already been set dont attach the click event again
      if (link.hasAttribute('data-ash-redirect-set')) {
        return;
      } else {
        //set redirect attr to true so it does not double initialize and cause two redirect tabs to open
        link.setAttribute('data-ash-redirect-set', 'true');
        //detect which link is clicked and send to redirect page
        link.addEventListener('click', function(event) {
          var clickedLink = this;
          var linkPath = clickedLink.getAttribute('href');
          var encodedLink = encodeURI(linkPath);
          //the text shown on the redirect page for the clickable link to by pass the timeout
          //this can be set with the data attribute `data-ash-redirLinkText` or 
          //it will default to the encoded uri of the url the link will redirect too
          var linkText =
            clickedLink.getAttribute('data-ash-redirLinkText') || linkPath;
          event.preventDefault();
          //create link obj for the redirect page
          var linkObj = {
            link: encodedLink,
            text: linkText
          }

          //setting linkObj in sessionStorage to grab when redirect page loads
          sessionStorage.setItem(index, JSON.stringify(linkObj));
          //open links with target="_blank" in new window
          if (clickedLink.getAttribute('target') === '_blank') {
            //using the index of the link to create the key in sessionStorage
            //the url would look like this on ...Redirect.html?redir=0
            window.open(redirect + '?redir=' + index, '_blank');
          } else {
            window.location = redirect + '?redir=' + index;
          }
        });
      }
    });

    //keep user on redirect page for [delay], then send to clicked url if page has class page redirect and redirect timer markup
    if ($('.pageRedirect').length && $('.redirCount').length) {
      var url = window.location.href;
      var finalLink = url.split('?redir=');
      var redirLink = document.querySelector('.main-content a');
      var count = document.querySelector('.redirCount');
      //get redirect link info from session storage
      var linkObj = sessionStorage.getItem(finalLink[1]);
      //parse linkObj
      //dummy obj
      /**
       * {
       *   link: 'https://google.com
       *   text: 'custom text here' or 'https//:google.com'
       * }
       */
      var linkObjParsed = JSON.parse(linkObj);
      //setting up link user can click to bypass the countdown and immediately navigate to the new url
      //sets the a href link info stored in sessionStorage
      redirLink.setAttribute('href', linkObjParsed.link);
      //sets the text with custom text from sessionStorage or the default which is the encoded url
      redirLink.innerText = linkObjParsed.text ||linkObjParsed.link;

      //if user does not click the link automatically navigate to the new url after the countdown is done
      window.setTimeout(function() {
        window.location = linkObjParsed.link;
      }, delay);

      //count down the seconds user has left on redirect page
      (function() {
        setInterval(function() {
          delaySeconds--;

          count.textContent =
            delaySeconds + (delaySeconds !== 1 ? ' seconds' : ' second');

          if (delaySeconds === 0) {
            clearInterval(delaySeconds);
          }
        }, 1000);
      })();
    }
  }
}

//get all external links in body of pages to redirect
function bodyLinkRedir(links) {
  var excludes = ['.noRedirect', '.marketplace', '.pageRedirect'].join(', ');

  //do no redirect marketplace and pages with noRedirect class
  if (document.querySelectorAll(excludes).length === 0) {
    var bodyLinks = links.body;
    //calling slice() method on the "array-like" nodeList to return an array to be used with a forEach in the linkRedirect function
    var modalLinks = Array.prototype.slice.call(
      document.querySelectorAll(
        '.ashModalCont a[href^="http"], .ashModalCont a[href^="//"]'
      )
    );
    var allLinks = bodyLinks.concat(modalLinks);
    if (allLinks.length > 0) {
      linkRedirect(allLinks);
    }
  }
}
//process body links to make body and hash link arrays
function bodyLinkProcessing() {
    var links = {
        body: [],
        hash: []
    };
  	//calling slice() method on the "array-like" nodeList to return an array to be used with a forEach
    var mainContainerLinks = Array.prototype.slice.call(
        document.querySelectorAll('.main-container a')
    );
    const currentUrlWithoutHash = window.location.href.replace(window.location.hash, "");

    mainContainerLinks.forEach(function (link) {
        var href = link.getAttribute('href');
        if (href && href.indexOf('#') > -1) {
            if (link.hash) {
                const fullHrefWithoutHash = link.href.replace(link.hash, "");
                if (currentUrlWithoutHash.localeCompare(fullHrefWithoutHash) === 0) {
                    links.hash.push(link);
                }
            }
        }
        if (href && href.indexOf('http') > -1) {
            links.body.push(link);
        }
    });
    return links;
}

function scrollJumpLinks(obj) {
  //jump links to scroll to position taking sticky header into account
  obj.addEventListener('click', function(e) {
    e.stopPropagation();
    e.preventDefault();

    var obj = this;
    var href = obj.getAttribute('href');
    scrollIt(href);
  });
}

function scrollIt(href) {
  //if link has a hash tag get its tagname
  if (href.indexOf('#') > -1) {
    //jump links can be relative links with hash tag following (ex: /Resources/Index#nurse)
    var hash = href.split('#')[1].toString();
    var hashSel = document.querySelector('#' + hash);
    //if tag name exists get its position and remove header height to scroll to top of page
    if (hash.length > 0 && hashSel) {
      var posElem = hashSel.offsetTop;
      var hdrHt = document.querySelector('header').offsetHeight;

      // Smooth Scrolling.
      window.scrollBy({
        top: posElem - hdrHt,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}

//get all external links in footer to redirect
function footerLinkRedir() {
  var footer = document.querySelector('footer');

  if (footer) {
    //calling slice() method on the "array-like" nodeList to return an array to be used with a forEach in the linkRedirect function
    var socialLinks = Array.prototype.slice.call(
      footer.querySelectorAll(
        '.social-links a[href^="http"], .social-links a[href^="//"], a.social[href^="http"], a.social[href^="//"] , .primaryFooter-sectionTwo-social a[href^="http"], .primaryFooter-sectionTwo-social a[href^="//"], a.primaryFooter-sectionTwo-icon[href^="http"], a.primaryFooter-sectionTwo-icon[href^="//"]'
      )
    );
    var accredLink = Array.prototype.slice.call(
      footer.querySelectorAll(
        '.footer-accreditation a[href^="http"], .footer-accreditation a[href^="//"]'
      )
    );
    var footerLinks = socialLinks.concat(accredLink);

    linkRedirect(footerLinks);
  }
}

//PURPOSE: gather all hash links (header, main container) and attach scroll events
function runScrollTo(links) {
  var hashLinks = links.hash;
  var hdrHashLinks = Array.prototype.slice.call(
    document.querySelectorAll('header a[href*="#"]')
  );
  var allHashLinks = hdrHashLinks.concat(hashLinks);

  // Loop thru an array of selected link element objects
  allHashLinks.forEach(function(linkObj) {
    scrollJumpLinks(linkObj);
  });
}

function runRedirect(links) {
  bodyLinkRedir(links);
  footerLinkRedir();
}
